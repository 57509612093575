
import { competitionService } from "@/_services";
import { toRefs, defineComponent, onMounted, ref, watch, reactive } from "vue";
import { useRequest } from "vue-request";
import MatchScoreResultBadge from "./MatchScoreResultBadge.vue";
import KnoctoutScore from "@/components/competitions/KnoctoutScore.vue";
import useCompetitionRepositories from "@/_composables/useCompetitionRepositories";
import { TreeSelect } from "ant-design-vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    competitionId: {
      type: Number,
      required: true,
    },
    subCompetitionId: {
      type: Number,
      required: true,
    },
  },
  components: {
    MatchScoreResultBadge,
    KnoctoutScore,
  },
  setup(props) {
    const {
      getKnoctoutFinal,
      getKnoctoutSemiFinal,
      getKnoctoutThirdPlaceMatch,
      getKnoctoutThirdRound,
      getKnoctoutSecondRound,
    } = useCompetitionRepositories();
    const store = useStore();

    const { competitionId, subCompetitionId } = toRefs(props);
    const state = reactive({
      groupStages: [] as any[],
      sectionGroup: [] as any[],
      versusMatch: [] as any[],
      final: [] as any[],
      semifinal: [] as any[],
      thirdmatch: [] as any[],
      thirdRound: [] as any[],
      secondRound: [] as any[],
      groupSecondRound: [] as any[],
      groupThirdRound: [] as any[],
      lengthMatch: false as Boolean,
      sectionList: [
        "FINAL",
        "SEMI_FINAL",
        "THIRD_PLACE_MATCH",
        "THIRD_ROUND",
        "SECOND_ROUND",
        "GROUP_STAGE",
      ],
    });
    const { loading, data: sections } = useRequest(
      competitionService.getCompetitionSections,
      {
        ready: ref<boolean>(!!competitionId.value && !!subCompetitionId.value),
        defaultParams: [competitionId.value, subCompetitionId.value],
        formatResult: ({ data }) => {
          const objData = state?.sectionList?.map((sec: any) => {
            return data.sub_competition.find(
              (s) => s.competition_section_round_name === sec
            );
          });
          const lengthS = objData.find((p) => p !== null || p !== undefined);
          if (lengthS !== undefined) {
            state.lengthMatch = true;
          }
          return objData;
        },
      }
    );
    const { run: getGroupStages } = useRequest(
      competitionService.getGroupStages,
      {
        manual: true,
        formatResult: ({ data }) => {
          return data?.competition_section;
        },
      }
    );
    onMounted(() => {});

    watch(
      () => loading.value,
      async () => {
        if (!loading.value && sections.value) {
          await Promise.all(
            sections.value.map(async (section) => {
              if (
                section?.section_type === "GROUP" &&
                section?.competition_section_round_name === "GROUP_STAGE"
              ) {
                const tmp = await getGroupStages(
                  competitionId.value,
                  subCompetitionId.value,
                  section?.id
                );
                state.sectionGroup =
                  tmp?.group_stages?.map((ele: any) => {
                    const { competition_group_stage_team_join: teams } = ele;
                    ele.dataTable = teams.map((e: any, index: number) => {
                      e.idx = index + 1;
                      return e;
                    });
                    ele.teamColumns = teams.map((t: any) => {
                      return {
                        teamId: `${t.team_id}`,
                        name: t.team_name,
                        name_en: t.team_name_en,
                        image: t.team_image,
                      };
                    });
                    ele.columns = {
                      th: [
                        {
                          title: "อันดับที่",
                          dataIndex: "idx",
                          width: "10%",
                          align: "center",
                        },
                        {
                          title: "ทีม",
                          dataIndex: "team_name",
                          slots: { customRender: "team" },
                        },
                        ...teams.map((t: any) => {
                          const { results } = t;
                          return {
                            dataIndex: `results`,
                            slots: {
                              title: `teamHeader-${t.team_id}`,
                              customRender: `teamResult-${t.team_id}`,
                            },
                            align: "center",
                          };
                        }),
                        {
                          title: "ลูกได้เสีย",
                          dataIndex: "gd",
                          align: "center",
                        },
                        {
                          title: "คะแนน",
                          dataIndex: "points",
                          align: "center",
                        },
                      ],
                      en: [
                        {
                          title: "Rank",
                          dataIndex: "idx",
                          width: "10%",
                          align: "center",
                        },
                        {
                          title: "Team",
                          dataIndex: "team_name",
                          slots: { customRender: "team" },
                        },
                        ...teams.map((t: any) => {
                          const { results } = t;
                          return {
                            dataIndex: `results`,
                            slots: {
                              title: `teamHeader-${t.team_id}`,
                              customRender: `teamResult-${t.team_id}`,
                            },
                            align: "center",
                          };
                        }),
                        {
                          title: "GD",
                          dataIndex: "gd",
                          align: "center",
                        },
                        {
                          title: "Score",
                          dataIndex: "points",
                          align: "center",
                        },
                      ],
                    };
                    return ele;
                  }) ?? [];
              }
              if (section?.competition_section_round_name === "FINAL") {
                const FINAL = await getKnoctoutFinal(
                  competitionId.value,
                  subCompetitionId.value
                );
                state.final = FINAL;
              }

              if (section?.competition_section_round_name === "SEMI_FINAL") {
                const SEMI_FINAL = await getKnoctoutSemiFinal(
                  competitionId.value,
                  subCompetitionId.value
                );
                state.semifinal = SEMI_FINAL;
              }

              if (
                section?.competition_section_round_name === "THIRD_PLACE_MATCH"
              ) {
                const THIRD_PLACE_MATCH = await getKnoctoutThirdPlaceMatch(
                  competitionId.value,
                  subCompetitionId.value
                );
                state.thirdmatch = THIRD_PLACE_MATCH;
              }

              if (section?.competition_section_round_name === "THIRD_ROUND") {
                const THIRD_ROUND = await getKnoctoutThirdRound(
                  competitionId.value,
                  subCompetitionId.value
                );

                if (section?.section_type !== "GROUP") {
                  state.thirdRound = THIRD_ROUND;
                } else {
                  state.groupThirdRound =
                    THIRD_ROUND?.competition_section?.group_stages?.map(
                      (ele: any) => {
                        const { competition_group_stage_team_join: teams } =
                          ele;
                        ele.dataTable = teams.map((e: any, index: number) => {
                          e.idx = index + 1;
                          return e;
                        });
                        ele.teamColumns = teams.map((t: any) => {
                          return {
                            teamId: `${t.team_id}`,
                            name: t.team_name,
                            name_en: t.team_name_en,
                            image: t.team_image,
                          };
                        });
                        ele.columns = {
                          th: [
                            {
                              title: "อันดับที่",
                              dataIndex: "idx",
                              width: "10%",
                              align: "center",
                            },
                            {
                              title: "ทีม",
                              dataIndex: "team_name",
                              slots: { customRender: "team" },
                            },
                            ...teams.map((t: any) => {
                              const { results } = t;
                              return {
                                dataIndex: `results`,
                                slots: {
                                  title: `teamHeader-${t.team_id}`,
                                  customRender: `teamResult-${t.team_id}`,
                                },
                                align: "center",
                              };
                            }),
                            {
                              title: "ลูกได้เสีย",
                              dataIndex: "gd",
                              align: "center",
                            },
                            {
                              title: "คะแนน",
                              dataIndex: "points",
                              align: "center",
                            },
                          ],
                          en: [
                            {
                              title: "Rank",
                              dataIndex: "idx",
                              width: "10%",
                              align: "center",
                            },
                            {
                              title: "Team",
                              dataIndex: "team_name",
                              slots: { customRender: "team" },
                            },
                            ...teams.map((t: any) => {
                              const { results } = t;
                              return {
                                dataIndex: `results`,
                                slots: {
                                  title: `teamHeader-${t.team_id}`,
                                  customRender: `teamResult-${t.team_id}`,
                                },
                                align: "center",
                              };
                            }),
                            {
                              title: "GD",
                              dataIndex: "gd",
                              align: "center",
                            },
                            {
                              title: "Score",
                              dataIndex: "points",
                              align: "center",
                            },
                          ],
                        };
                        return ele;
                      }
                    ) ?? [];
                }
              }

              if (section?.competition_section_round_name === "SECOND_ROUND") {
                const SECOND_ROUND = await getKnoctoutSecondRound(
                  competitionId.value,
                  subCompetitionId.value
                );

                if (section?.section_type !== "GROUP") {
                  state.secondRound = SECOND_ROUND;
                } else {
                  state.groupSecondRound =
                    SECOND_ROUND?.competition_section?.group_stages?.map(
                      (ele: any) => {
                        const { competition_group_stage_team_join: teams } =
                          ele;
                        ele.dataTable = teams.map((e: any, index: number) => {
                          e.idx = index + 1;
                          return e;
                        });
                        ele.teamColumns = teams.map((t: any) => {
                          return {
                            teamId: `${t.team_id}`,
                            name: t.team_name,
                            name_en: t.team_name_en,
                            image: t.team_image,
                          };
                        });
                        ele.columns = {
                          th: [
                            {
                              title: "อันดับที่",
                              dataIndex: "idx",
                              width: "10%",
                              align: "center",
                            },
                            {
                              title: "ทีม",
                              dataIndex: "team_name",
                              slots: { customRender: "team" },
                            },
                            ...teams.map((t: any) => {
                              const { results } = t;
                              return {
                                dataIndex: `results`,
                                slots: {
                                  title: `teamHeader-${t.team_id}`,
                                  customRender: `teamResult-${t.team_id}`,
                                },
                                align: "center",
                              };
                            }),
                            {
                              title: "ลูกได้เสีย",
                              dataIndex: "gd",
                              align: "center",
                            },
                            {
                              title: "คะแนน",
                              dataIndex: "points",
                              align: "center",
                            },
                          ],
                          en: [
                            {
                              title: "Rank",
                              dataIndex: "idx",
                              width: "10%",
                              align: "center",
                            },
                            {
                              title: "Team",
                              dataIndex: "team_name",
                              slots: { customRender: "team" },
                            },
                            ...teams.map((t: any) => {
                              const { results } = t;
                              return {
                                dataIndex: `results`,
                                slots: {
                                  title: `teamHeader-${t.team_id}`,
                                  customRender: `teamResult-${t.team_id}`,
                                },
                                align: "center",
                              };
                            }),
                            {
                              title: "GD",
                              dataIndex: "gd",
                              align: "center",
                            },
                            {
                              title: "Score",
                              dataIndex: "points",
                              align: "center",
                            },
                          ],
                        };
                        return ele;
                      }
                    ) ?? [];
                }
              }

              // if (section_type === "GROUP" && section?.competition_section_round_name === "SECOND_ROUND") {
              //   const SECOND_ROUND_GROUP = await getKnoctoutSecondRound(
              //     competitionId.value,
              //     subCompetitionId.value
              //   );
              //   console.log("SECOND_ROUND_GROUP", SECOND_ROUND_GROUP);
              // }
              return section;
            })
          );
          // const groupStageSections = sections.value.filter(ele => ele.section_type === 'GROUP')
          // const result = await Promise.all(groupStageSections.map(async ele => await getGroupStages(subCompetitionId.value, ele.id)))
          // result.forEach(ele => {
          //   const { competition_section_id } = ele
          //   console.log('competition_section_id', competition_section_id)
          // })
          // state.groupStages = [...result]
        }
      }
    );

    return {
      store,
      sections,
      ...toRefs(state),
    };
  },
});
